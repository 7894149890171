import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { MenuApiService } from 'src/lib/repository/menu/menu.api';
import { BrandService } from 'src/lib/services/brand.service';
import { HomepageApiService } from '../repository/home-screen/homepage.api';
import { DynamicColorService } from '../services/dynamic-color.service';
import { HomeScreenService } from '../services/home-screen.service';
import { LanguageService } from '../services/language.service';
import { PermissionService } from '../services/permission.service';

/**
 * HomeScreen, Menu 로딩에 사용되는 Guard
 */
@Injectable({
  providedIn: 'root',
})
export class HomepageMasterGuard {
  constructor(
    private homepageApiService: HomepageApiService,
    private menuApiService: MenuApiService,
    private dynamicColorService: DynamicColorService,
    private router: Router,
    private location: Location,
    private languageService: LanguageService,
    private brandService: BrandService,
    private permissionService: PermissionService,
    private homeScreenService: HomeScreenService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { queryParams } = this.router.parseUrl(this.location.path());

    // Brand 조회
    return this.homepageApiService.getBrand().pipe(
      mergeMap((brand) => {
        this.brandService.init(brand);
        this.permissionService.init(brand);

        return this.homepageApiService.getHomeScreen(brand.id!).pipe(
          map((homeScreen) => {
            // TODO: 구글 자동 번역 여부를 languageButtonVisible 이외의 설정으로 별도 관리할지 검토
            // this.languageService.init(brand, homeScreen.languageButtonVisible); 
            this.languageService.init(brand, true);
            this.homeScreenService.init(homeScreen);
            // 스타일 색상 설정
            const primaryColor =
              queryParams?.['previewPrimaryColor'] ?? homeScreen?.primaryColor;

            const secondaryColor =
              queryParams?.['previewSecondaryColor'] ??
              homeScreen?.secondaryColor;

            const tertiaryColor =
              queryParams?.['previewTertiaryColor'] ??
              homeScreen?.tertiaryColor;

            const dangerColor =
              queryParams?.['previewDangerColor'] ?? homeScreen?.dangerColor;

            this.updateDynamicColor(
              primaryColor,
              secondaryColor,
              tertiaryColor,
              dangerColor,
            );

            return true;
          }),
          mergeMap(() => {
            return this.menuApiService
              .getMenu(this.homeScreenService.homeScreen.id)
              .pipe(map(() => true));
          }),
          catchError(() => of(true)),
        );
      }),
      map((res) => res),
    );
  }

  private updateDynamicColor(
    primaryColor?: string,
    secondaryColor?: string,
    tertiaryColor?: string,
    dangerColor?: string,
  ): void {
    this.dynamicColorService.setPrimaryColor(primaryColor ?? '#145a9f');
    this.dynamicColorService.setSecondaryColor(secondaryColor ?? '#df144c');
    this.dynamicColorService.setTertiaryColor(tertiaryColor ?? '#f0b536');
    // 부트스트랩 기본 danger
    this.dynamicColorService.setDangerColor(dangerColor ?? '#dc3545');
  }
}
