import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { catchError, filter, finalize, forkJoin, tap } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/lib/auth/auth.service';
import { BookingApi } from 'src/lib/repository/booking/booking.api';
import { HotelGoodsApi } from 'src/lib/repository/hotel-goods/hotel-goods.api';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { IPayment } from 'src/lib/repository/payment/payment.model';
import {
  TmHotel,
  TmPlanSearch,
  TmRoomSearch,
} from 'src/lib/repository/temairazu/tm-plan.model';
import { TmTopApi } from 'src/lib/repository/temairazu/tm-top.api';
import { BrandService } from 'src/lib/services/brand.service';
import { DATE_FORMAT } from 'src/lib/utils';
import { TmRoomplanApi } from '../../../../lib/repository/temairazu/tm-roomplan.api';
import { DialogService } from '../../../services/dialog.service';
import { ImgGridComponent } from '../../img-grid/img-grid.component';
import { SharedModule } from '../../shared/shared.module';
import { TmCancelPolicyTableComponent } from '../tm-cancel-policy-table/tm-cancel-policy-table.component';
import { TmMrhstBookingFormComponent } from '../tm-mrhst-booking-form/tm-mrhst-booking-form.component';

/**
 * 테마이라즈 예약 쿼리파라미터 타입
 */
export interface TmMrhstBookingQueryParams {
  roomCnt: number;
  tourNumber: number;
  checkInDt: string;
  checkOutDt: string;
  nights: number;
  roomId: number;
}

/**
 * 테마이라즈 시설 예약 페이지 컴포넌트
 */
@Component({
  selector: 'trnty-tm-mrhst-booking',
  standalone: true,
  imports: [
    SharedModule,
    TmMrhstBookingFormComponent,
    TmCancelPolicyTableComponent,
    ImgGridComponent,
  ],
  templateUrl: './tm-mrhst-booking.component.html',
  styleUrl: './tm-mrhst-booking.component.scss',
})
export class TmMrhstBookingComponent {
  /**
   * 폼 컴포넌트
   */
  formComponent!: TmMrhstBookingFormComponent;

  /**
   * 투어니티 시설 식별번호
   */
  mrhstId: number;

  /**
   * 테마이라즈 예약 조건
   */
  bookingCondition: TmMrhstBookingQueryParams;

  /**
   * 투어니티 숙박 상품
   */
  hotelGoods?: IHotelGoods;

  /**
   * 이미지 목록
   */
  imgUrlList: string[] = [];

  /**
   * 테마이라즈 호텔
   */
  hotel!: TmHotel;

  /**
   * 테마이라즈 공실 검색 결과
   */
  roomPlanSearch!: TmRoomSearch;

  /**
   * 정렬된 플랜 목록
   */
  sortedPlanList: TmPlanSearch[] = [];

  /**
   * 선택한 테마이라즈 플랜
   */
  selectedPlan?: TmPlanSearch;

  /**
   * 브랜드명
   */
  brandNm: string;

  /**
   * 일본어 페이지임을 표현
   */
  @HostBinding('lang') lang = 'ja';

  /**
   * 번역 사용
   */
  @HostBinding('translate') translate = 'yes';

  /**
   * 스크롤 대상 엘리먼트 참조
   *
   * 예약 정보 입력 단락 바로 위
   */
  @ViewChild('scrollTarget') scrollTarget!: ElementRef<HTMLDivElement>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private authService: AuthService,
    private brandService: BrandService,
    private dialogService: DialogService,
    private tmTopApi: TmTopApi,
    private tmRoomplanApi: TmRoomplanApi,
    private hotelGoodsApiService: HotelGoodsApi,
    private bookingApiService: BookingApi,
    private loadingService: LoadingService,
  ) {
    // 브랜드명
    this.brandNm = this.brandService.brand.brandNm!;

    // 시설 식별번호
    const { id } = this.activatedRoute.snapshot.params;
    this.mrhstId = Number(id);

    // 예약 조건
    const { checkInDt, nights, roomCnt, tourNumber, roomId } =
      this.activatedRoute.snapshot.queryParams;

    this.bookingCondition = {
      checkInDt: checkInDt,
      checkOutDt: dayjs(checkInDt).add(nights, 'day').format(DATE_FORMAT),
      nights: Number(nights),
      roomCnt: Number(roomCnt),
      tourNumber: Number(tourNumber),
      roomId: Number(roomId),
    };

    this.setRoomAndPlan();
    this.setHotelGoods();
  }

  /**
   * 폼 컴포넌트 초기화시
   */
  onFormInit(component: TmMrhstBookingFormComponent): void {
    this.formComponent = component;
    this.formComponent.setValue(this.authService.account.userInfo);
  }

  /**
   * 플랜 클릭시
   */
  onPlanClick(plan: TmPlanSearch): void {
    if (environment.production) {
      this.dialogService.alert('아직 이용하실수 없습니다.').subscribe();
      return;
    }

    this.dialogService
      .alert(
        '운영 환경이 아니므로 예약 가능합니다. 실제로 예약, 결제되므로 주의해주시기 바랍니다.',
      )
      .subscribe();

    this.selectedPlan = plan;
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * 예약 클릭시
   */
  onBookingClick(): void {
    if (!this.selectedPlan) {
      return;
    }

    if (this.loadingService.isLoading) {
      return;
    }

    // 폼 컴포넌트 유무를 한번에 검사하기 위해 !valid
    if (!this.formComponent?.formGroup.valid) {
      this.formComponent?.formGroup.markAllAsTouched();
      this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    const message = this.translateService.instant('notResponsibleToThisGoods', {
      brandNm: this.brandNm,
    });

    this.dialogService
      .confirm(message)
      .pipe(
        filter((ok) => !!ok),
        tap(() => {
          this.makeBooking();
        }),
      )
      .subscribe();

    return;
  }

  /**
   * 테마이라즈 객실, 플랜 정보 조회하여 입력
   */
  private setRoomAndPlan(): void {
    const { checkInDt, nights, roomCnt, tourNumber, roomId } =
      this.bookingCondition;

    const params = {
      CheckInDate: checkInDt,
      StayDays: nights,
      RoomsNum: roomCnt,
      AdultsNum: tourNumber,
      ChildrenNum: 0,
      RoomIds: [roomId],
    };

    forkJoin({
      hotel: this.tmTopApi.getHotel(this.mrhstId),
      search: this.tmRoomplanApi.getRoomplanSearch(this.mrhstId, params),
    })
      .pipe(
        tap(({ hotel, search }) => {
          this.hotel = hotel.Hotel;
          [this.roomPlanSearch] = search.Rooms;

          // 객실, 호텔 사진 목록 병합
          const pictureList = [
            ...this.roomPlanSearch.Pictures,
            ...this.hotel.Pictures,
          ];

          // 객실 이미지 목록
          this.imgUrlList = pictureList.map(({ Url }) => Url);

          // 플랜 필터, 정렬
          this.sortedPlanList = this.roomPlanSearch.Plans.filter((plan) => {
            // 사전 결제 가능한 플랜만 필터
            return plan.Payment === 1 || plan.Payment === 3;
          }).sort((a, b) => {
            // 플랜 가격 오름차순 정렬
            return a.TotalPrice - b.TotalPrice;
          });
        }),
      )
      .subscribe();
  }

  /**
   * 매장 정보 획득
   */
  private setHotelGoods(): void {
    const params = { mrhstId: this.mrhstId };

    this.hotelGoodsApiService
      .findPage(params)
      .pipe(
        tap(({ content }) => {
          [this.hotelGoods] = content;
        }),
      )
      .subscribe();
  }

  /**
   * 테마이라즈 RoomPax 객체
   *
   * 객실별 남녀 수 목록
   */
  private getRoomPax(): any[] {
    const roomPax = {
      MaleNum: this.bookingCondition.tourNumber,
      FemaleNum: 0,
    };

    return Array(this.bookingCondition.roomCnt).fill(roomPax);
  }

  private makeBooking(): void {
    const { CheckInTime, ...rest } = this.formComponent.getValue();
    const { PlanId, TotalPrice, CheckoutTimeEndH, CheckoutTimeEndM } =
      this.selectedPlan!;

    // 테마이라즈 예약
    const temairazu = {
      RoomId: this.bookingCondition.roomId,
      PlanId,
      CheckInDate: this.bookingCondition.checkInDt,
      CheckInTime,
      StayDays: this.bookingCondition.nights,
      RoomsNum: this.bookingCondition.roomCnt,
      AdultsNum: this.bookingCondition.tourNumber,
      ChildrenNum: 0,
      ZipCode: '0000000',
      Prefectures: '海外',
      Address: '海外',
      // 1: 사전 결제
      Payment: 1,
      TotalPrice,
      RoomPax: this.getRoomPax(),
      ...rest,
    };

    // 투어니티 결제
    const payment: IPayment = {
      amt: TotalPrice,
      paymentType: 'CREDIT_CARD',
      currencyCd: 'JPY',
    };

    // 체크아웃 시간
    const returnTm = dayjs()
      .hour(CheckoutTimeEndH)
      .minute(CheckoutTimeEndM)
      .format('HH:mm');

    // 투어니티 예약
    const bookingParams: any = {
      bookingType: 'HOTEL',
      bookingStateCode: 'ESTIMATE',
      selectedMrhstId: this.mrhstId,
      selectedGoodsId: this.hotelGoods!.id,
      selectedGoodsName: this.hotelGoods?.goodsNm,
      tourNumber: this.bookingCondition.tourNumber,
      startDate: this.bookingCondition.checkInDt,
      returnDate: this.bookingCondition.checkOutDt,
      startTm: CheckInTime,
      returnTm,
      bookingSubStateCode: '결제완료',
      bookingDetailUrl: `${window.location.origin}/tmbooking`,
      representative: { id: this.authService.account.id },
      paymentList: [payment],
      temairazu,
    };

    this.loadingService.start();

    this.bookingApiService
      .create(bookingParams)
      .pipe(
        tap((booking) => {
          const tmBookingInfo = JSON.parse(booking.tmBookingInfoJson || '{}');
          const { PaymentUrl } = tmBookingInfo;

          // 사전결제이면
          if (PaymentUrl) {
            // 사전 결제 페이지로 이동
            window.location.href = PaymentUrl;
          } else {
            // 아니면 예약 내역 상세로 이동
            this.router.navigate(['tmbooking', booking.id]);
          }
        }),
        finalize(() => {
          this.loadingService.stop();
        }),
        catchError((e) => {
          return this.dialogService.alert(e.message);
        }),
      )
      .subscribe();
  }
}
